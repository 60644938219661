import { tns } from 'tiny-slider/src/tiny-slider';


(function ($) {

    function remove_aria_hidden_slider_cover() {
        const $tns_nav = document.querySelectorAll(".act-galerie-cover__gallery .tns-controls button");

        if ($tns_nav) {

            $tns_nav.forEach(function ($nav) {
                var value = $nav.getAttribute('data-controls');
                $nav.setAttribute('aria-label', value);
                $nav.setAttribute('title', value);
            });
        }
        const $tns_hidden = document.querySelectorAll(".act-galerie-cover__gallery .tns-item");

        if ($tns_hidden) {

            $tns_hidden.forEach(function ($item) {
/* on ne peut pas faire ca, tiny se sert de ca pour mettre la classe active sur la bonne slide */
                // $item.removeAttribute('aria-hidden');
            });
        }
    }
    const $galleries = document.querySelectorAll('.act-gallery-cover__gallery');

    if (location.pathname.indexOf('wp-admin') === -1) {

        if ($galleries.length > 0) {
            $galleries.forEach(function ($g) {
            var slider = tns({
                container: $g,
                mode: 'gallery',
                //animateIn: 'fadeInDown',
                //animateOut: 'fadeOutDown',
                animateDelay: 750,
                autoplayTimeout: 6000,
                controls: false,
                autoplay: true,
                autoplayButton: false,
                nav: $g.dataset.tnsDots === '1',
            });
            slider.events.on('indexChanged', function (info, eventName) {
                remove_aria_hidden_slider_cover();
    
            });
        });
            remove_aria_hidden_slider_cover();
        }
    }
   

    $(document).ready(function () {
        if ($('.l-scroll').length) {
            $('body').on('click', '.l-scroll', function () {
                var $scrollTo = $('.act-gallery-cover').next();
                $("html, body").animate({ scrollTop: $scrollTo.offset().top - $(".l-header").height() + 2} );

            });

        }
    });
})(jQuery);
